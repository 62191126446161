import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, ResponsiveContext } from 'grommet';

const Customer = () => {
  let appDirection = 'column';
  let voiceMessageFlexBasis = '90%';

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
          voiceMessageFlexBasis = '90%';
        } else {
          appDirection = 'column';
          voiceMessageFlexBasis = '100%';
        }
        return (
          <>
            <Box style={{ marginBottom: '2.5rem' }}>
              <Box>
                <Box align="center">
                  <Box align="center">
                    <h2 style={{ textAlign: 'center', fontSize: '2.5rem', lineHeight: '3.5rem' }}>
                      お客様の声
                    </h2>
                  </Box>
                </Box>
              </Box>
              <Box border="all" style={{ marginBottom: '1rem', padding: '1rem 2rem 1rem 1rem' }}>
                <Box direction="row" style={{ marginTop: '2rem', marginBottom: '2.5rem' }}>
                  <ResponsiveContext.Consumer gap="medium" align="center" justify="around">
                    {(size) => {
                      if (size !== 'xsmall' && size !== 'small') {
                        return (
                          <Box style={{ flexBasis: '10%' }} align="center" justify="center">
                            <StaticImage
                              objectFit="contain"
                              src="../../icons/baseline_thumb_up_alt_black_48dp.png"
                              formats={['auto', 'webp', 'avif']}
                              alt={'good'}
                              width={70}
                              height={70}
                              imgClassName="ofi"
                            />
                          </Box>
                        );
                      }
                    }}
                  </ResponsiveContext.Consumer>
                  <Box style={{ flexBasis: voiceMessageFlexBasis }}>
                    <span style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                      フロアが５つあり、監督者が現場の様子を確認しようとすると
                      <span style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold' }}>
                        大変な時間が掛かる
                      </span>
                      。危険運転が自動で通知されたり、必要に応じて現場の映像を確認できるのは、
                      <span style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold' }}>
                        人手不足の中でありがたいサービス
                      </span>
                      {'(面倒なSDカードの抜き差しも必要ない)。'}
                    </span>
                  </Box>
                </Box>
                <Box direction="row" style={{ marginBottom: '2.5rem' }}>
                  <ResponsiveContext.Consumer gap="medium" align="center" justify="around">
                    {(size) => {
                      if (size !== 'xsmall' && size !== 'small') {
                        return (
                          <Box style={{ flexBasis: '10%' }} align="center" justify="center">
                            <StaticImage
                              objectFit="contain"
                              src="../../icons/baseline_thumb_up_alt_black_48dp.png"
                              formats={['auto', 'webp', 'avif']}
                              alt={'good'}
                              width={70}
                              height={70}
                              imgClassName="ofi"
                            />
                          </Box>
                        );
                      }
                    }}
                  </ResponsiveContext.Consumer>
                  <Box style={{ flexBasis: voiceMessageFlexBasis }}>
                    <span style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                      <span style={{ fontWeight: 'bold' }}>ドラレコは事故があった時にだけ活用</span>
                      していたが、ドラレコConnectの機能によって、ドラレコが日々の
                      <span style={{ fontWeight: 'bold' }}>安全管理のツールになった</span>。
                    </span>
                    <span style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                      また、運転者への監視効果も働き、
                      <span style={{ fontWeight: 'bold' }}>安全運転の意識が高まった</span>と感じる。
                    </span>
                  </Box>
                </Box>
                <Box direction="row" style={{ marginBottom: '2.5rem' }}>
                  <ResponsiveContext.Consumer gap="medium" align="center" justify="around">
                    {(size) => {
                      if (size !== 'xsmall' && size !== 'small') {
                        return (
                          <Box style={{ flexBasis: '10%' }} align="center" justify="center">
                            <StaticImage
                              objectFit="contain"
                              src="../../icons/baseline_thumb_up_alt_black_48dp.png"
                              formats={['auto', 'webp', 'avif']}
                              alt={'good'}
                              width={70}
                              height={70}
                              imgClassName="ofi"
                            />
                          </Box>
                        );
                      }
                    }}
                  </ResponsiveContext.Consumer>
                  <Box style={{ flexBasis: voiceMessageFlexBasis }}>
                    <span style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                      優良ドライバーだと思っていた運転者が意外と危険な運転をしていた。
                    </span>
                    <span style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                      逆に、目立たないながら
                      <span style={{ fontWeight: 'bold' }}>
                        着実に安全運転をしている運転手の存在にも気づくことができた
                      </span>
                      。安全の活動は注意喚起をすることが多いが、
                      <span style={{ fontWeight: 'bold' }}>安全運転成績を用いた表彰制度</span>
                      を検討していきたい
                    </span>
                  </Box>
                </Box>
                <Box direction="row" style={{ marginBottom: '2.5rem' }}>
                  <ResponsiveContext.Consumer gap="medium" align="center" justify="around">
                    {(size) => {
                      if (size !== 'xsmall' && size !== 'small') {
                        return (
                          <Box style={{ flexBasis: '10%' }} align="center" justify="center">
                            <StaticImage
                              objectFit="contain"
                              src="../../icons/report_problem.png"
                              formats={['auto', 'webp', 'avif']}
                              alt={'alert'}
                              width={70}
                              height={60}
                              imgClassName="ofi"
                            />
                          </Box>
                        );
                      }
                    }}
                  </ResponsiveContext.Consumer>
                  <Box style={{ flexBasis: voiceMessageFlexBasis }}>
                    <span style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                      アルミ溶湯の入った搬送鍋に車両がぶつかる瞬間を映像が捉えていた。
                    </span>
                    <span style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                      重大な事故につながりかねないので、要因特定及び再発防止を徹底した。
                    </span>
                  </Box>
                </Box>
                <Box direction="row" style={{ marginBottom: '2.5rem' }}>
                  <ResponsiveContext.Consumer gap="medium" align="center" justify="around">
                    {(size) => {
                      if (size !== 'xsmall' && size !== 'small') {
                        return (
                          <Box style={{ flexBasis: '10%' }} align="center" justify="center">
                            <StaticImage
                              objectFit="contain"
                              src="../../icons/report_problem.png"
                              formats={['auto', 'webp', 'avif']}
                              alt={'alert'}
                              width={70}
                              height={60}
                              imgClassName="ofi"
                            />
                          </Box>
                        );
                      }
                    }}
                  </ResponsiveContext.Consumer>
                  <Box style={{ flexBasis: voiceMessageFlexBasis }}>
                    <span style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                      {
                        '作業観察を抜き取りで行ったところ、ながら運転や、シートベルト未着用など不安全運転やルール違反が散見された。安全より生産性を優先してしまう現場の実態を目の当たりにし、職場をあげて体質改善に取り組むこととした。'
                      }
                    </span>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default Customer;
