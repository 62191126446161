import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, ResponsiveContext } from 'grommet';

const Top = () => {
  let appDirection = 'column';
  let textPaddingRight = null;

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
          textPaddingRight = '40px';
        } else {
          appDirection = 'column';
          textPaddingRight = null;
        }
        return (
          <>
            <Box style={{ marginBottom: '2.5rem' }}>
              <Box
                direction="row"
                justify="center"
                align="center"
                style={{ marginBottom: '1.5rem' }}
              >
                <StaticImage
                  objectFit="contain"
                  src="../../icons/baseline_linked_camera_black_48dp.png"
                  formats={['auto', 'webp', 'avif']}
                  alt={'ドラレコConnect'}
                  width={60}
                  height={60}
                  imgClassName="ofi"
                />
                <h1 style={{ fontSize: '2rem', marginBottom: '0rem' }}>ドラレコConnect</h1>
              </Box>
              <Box align="center">
                <Box align="center" style={{ marginBottom: '2rem' }}>
                  <h2 style={{ textAlign: 'center', fontSize: '2.5rem', lineHeight: '3.5rem' }}>
                    映像データで現場は変わる
                  </h2>
                  <span style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                    ドライブレコーダーとIoT技術で実現する安全管理のデジタル化
                  </span>
                </Box>
                <Box align="center">
                  <StaticImage
                    objectFit="contain"
                    src="../../images/driverecorderSolution/driverecorderConnect/driverecorderConnectImage.png"
                    formats={['auto', 'webp', 'avif']}
                    alt={'ドラレコConnect'}
                    height={500}
                    imgClassName="ofi"
                    imgStyle={{ width: '100%' }}
                  />
                </Box>
              </Box>
            </Box>
            <Box style={{ marginBottom: '2.5rem' }}>
              <Box border="all" style={{ marginBottom: '1rem' }}>
                <Box pad="medium" direction={appDirection} style={{ marginBottom: '1.5rem' }}>
                  <Box
                    basis="1/2"
                    height={{ min: '100px' }}
                    style={{ paddingRight: textPaddingRight }}
                    justify="center"
                  >
                    <h3 style={{ fontSize: '2.5rem', lineHeight: '3.5rem' }}>すぐに通知</h3>
                    <span style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                      ドラレコが事故や危険運転を検知したら、管理者様へメール通知。
                    </span>
                    <span className="br"></span>
                    <span style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                      発生日時、対象車両、運転者等の情報と合わせ、記録された動画をそのままスマホ、ＰＣでご確認いただけます。
                    </span>
                  </Box>
                  <Box
                    basis="1/2"
                    flex="shrink"
                    height={{ min: '100px' }}
                    direction="row"
                    justify="center"
                  >
                    <StaticImage
                      objectFit="contain"
                      src="../../images/driverecorderSolution/driverecorderConnect/driverecorderConnectAlert.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'アラート'}
                      imgClassName="ofi"
                      height={{ min: '100px' }}
                      imgStyle={{ width: '100%' }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box border="all" style={{ marginBottom: '1rem' }}>
                <Box pad="medium" direction={appDirection} style={{ marginBottom: '1.5rem' }}>
                  <Box
                    basis="1/2"
                    height={{ min: '100px' }}
                    style={{ paddingRight: textPaddingRight }}
                    justify="center"
                  >
                    <h3 style={{ fontSize: '2.5rem', lineHeight: '3.5rem' }}>閾値調整</h3>
                    <span style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                      メール通知の対象とする衝撃値を自由に調整。
                    </span>
                    <span style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                      各現場、車両に最適な値に設定することで、本当に危険な運転に絞り込んで通知を受けられます。
                    </span>
                  </Box>
                  <Box
                    basis="1/2"
                    flex="shrink"
                    height={{ min: '100px' }}
                    direction="row"
                    justify="center"
                  >
                    <StaticImage
                      objectFit="contain"
                      src="../../images/driverecorderSolution/driverecorderConnect/driverecorderConnectThreshold.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'閾値調整'}
                      imgClassName="ofi"
                      height={{ min: '100px' }}
                      imgStyle={{ width: '100%' }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box border="all" style={{ marginBottom: '1rem' }}>
                <Box pad="medium" direction={appDirection} style={{ marginBottom: '1.5rem' }}>
                  <Box
                    basis="1/2"
                    height={{ min: '100px' }}
                    style={{ paddingRight: textPaddingRight }}
                    justify="center"
                  >
                    <h3 style={{ fontSize: '2.5rem', lineHeight: '3.5rem' }}>いつでもどこでも</h3>
                    <span style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                      現場から離れた場所にいても、各車両の稼働状況（稼働中/停止中、運転者）が一目でわかります。
                    </span>
                    <span className="br"></span>
                    <span style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                      {
                        'また、クラウドに保管されたドラレコ映像は見たい時間帯を指定して確認できるので、運転者の抜き取りの作業観察、新人運転者の習熟確認にご活用いただけます。'
                      }
                    </span>
                  </Box>
                  <Box style={{ flexBasis: '50%' }}>
                    <Box justify="center">
                      <span style={{ textAlign: 'center' }}>
                        ＜稼動状況（緑：稼働中、赤：停止中）＞
                      </span>
                      <Box justify="center" direction="row">
                        <StaticImage
                          objectFit="contain"
                          src="../../images/driverecorderSolution/driverecorderConnect/driverecorderConnectMonitor.png"
                          formats={['auto', 'webp', 'avif']}
                          alt={'稼動状況'}
                          height={{ min: '100px' }}
                          imgClassName="ofi"
                          imgStyle={{ width: '100%' }}
                        />
                      </Box>
                    </Box>
                    <Box justify="center" style={{ marginTop: '1rem' }}>
                      <span style={{ textAlign: 'center' }}>＜作業観察（時間帯選択画面）＞</span>
                      <Box justify="center" direction="row">
                        <StaticImage
                          objectFit="contain"
                          src="../../images/driverecorderSolution/driverecorderConnect/driverecorderConnectPastMovie.png"
                          formats={['auto', 'webp', 'avif']}
                          alt={'作業観察'}
                          imgClassName="ofi"
                          height={{ min: '100px' }}
                          imgStyle={{ width: '100%' }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box border="all" style={{ marginBottom: '1rem' }}>
                <Box pad="medium" direction={appDirection} style={{ marginBottom: '1.5rem' }}>
                  <Box
                    basis="1/2"
                    height={{ min: '100px' }}
                    style={{ paddingRight: textPaddingRight }}
                    justify="center"
                  >
                    <h3 style={{ fontSize: '2.5rem', lineHeight: '3.5rem' }}>レポート</h3>
                    <span style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                      月間の累計稼働時間（車両毎）、時間帯ごとの稼働台数、各運転者の安全運転成績表を集計し、グラフで表示します。
                    </span>
                    <span style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                      これまで見えにくかった現場の情報を確認してみましょう。
                    </span>
                  </Box>
                  <Box
                    basis="1/2"
                    flex="shrink"
                    height={{ min: '100px' }}
                    direction="row"
                    justify="center"
                  >
                    <StaticImage
                      objectFit="contain"
                      src="../../images/driverecorderSolution/driverecorderConnect/driverecorderConnectSafeDrivingReport.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'安全運転成績表'}
                      imgClassName="ofi"
                      height={{ min: '100px' }}
                      imgStyle={{ width: '100%' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default Top;
