import * as React from 'react';
import Layout from '../components/layout/layout';
import Seo from '../components/seo';
import DriverecorderConnect from '../components/driverecorderConnect/driverecorderConnect';
import Footer from '../components/driverecorderConnect/footer';

const DriverecorderSolutionPage = () => (
  <Layout>
    <Seo title="ドラレコソリューション" />
    <DriverecorderConnect />
    <Footer />
  </Layout>
);

export default DriverecorderSolutionPage;
