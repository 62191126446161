import * as React from 'react';
import Top from './top';
import Customer from './customer';

const DriverecorderConnect = () => {
  return (
    <>
      <Top />
      <Customer />
    </>
  );
};

export default DriverecorderConnect;
